var render = function () {
  var _vm$editor, _vm$editor3, _vm$editor4, _vm$editor6, _vm$editor8, _vm$editor10, _vm$editor12, _vm$editor14, _vm$editor16, _vm$editor17, _vm$editor19, _vm$editor21, _vm$editor23, _vm$editor25, _vm$editor27, _vm$editor29, _vm$editor31, _vm$editor33, _vm$editor35, _vm$editor37, _vm$editor38, _vm$editor39, _vm$editor41, _vm$editor43, _vm$editor45, _vm$editor46, _vm$editor48, _vm$editor50, _vm$editor52, _vm$editor54, _vm$editor56, _vm$editor58, _vm$editor59, _vm$editor61, _vm$editor63, _vm$editor65, _vm$editor67, _vm$editor69, _vm$editor71, _vm$editor73, _vm$editor75, _vm$editor77, _vm$editor79, _vm$editor80, _vm$editor81, _vm$editor83, _vm$template, _vm$template2, _vm$tableModal, _vm$tableModal2, _vm$tableModal3, _vm$watermarkModal;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['wysiwyg-editor', _vm.simple ? 'wysiwyg-editor--simple' : '', _vm.isCleanMode || _vm.isAutentiMode ? 'wysiwyg-editor--clean' : '', _vm.readOnlyMode ? 'wysiwyg-editor--read' : '', !_vm.editable ? 'wysiwyg-editor--not-editable' : ''],
    on: {
      "click": function click($event) {
        _vm.selectIsOpened = false;
      }
    }
  }, [_c('div', {
    staticClass: "wysiwyg-editor__inner"
  }, [_vm._t("tabs"), !_vm.simple ? _c('div', {
    staticClass: "wysiwyg-editor__info"
  }, [_vm._t("additionalHeaderFields")], 2) : _vm._e(), _c('div', {
    staticClass: "wysiwyg-editor__body"
  }, [!_vm.simple && !_vm.readOnlyMode ? _c('div', {
    staticClass: "wysiwyg-editor__toolbar"
  }, [_vm.isCleanMode || _vm.isAutentiMode ? _c('simplebar', {
    staticClass: "wysiwyg-editor__toolbar-wrapper",
    attrs: {
      "data-simplebar-auto-hide": "false"
    }
  }, [_c('div', {
    class: ['toolbar', !_vm.editable ? 'disabled' : '']
  }, [!(_vm.isCleanMode || _vm.isAutentiMode) ? _c('div', {
    class: ['toolbar-select', _vm.selectIsOpened ? 'opened' : ''],
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.selectIsOpened = !_vm.selectIsOpened;
      }
    }
  }, [_c('div', {
    staticClass: "toolbar-select__value"
  }, [_c('span', [_vm._v(_vm._s(_vm.currentTextType))]), _c('img', {
    staticClass: "toolbar-select__arrow",
    attrs: {
      "src": require("@/assets/icons/arrow.svg")
    }
  })]), _c('simplebar', {
    staticClass: "toolbar-select__dropdown",
    attrs: {
      "data-simplebar-auto-hide": "false"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.selectIsOpened = false;
      }
    }
  }, [_c('div', {
    class: ['toolbar-select__option', (_vm$editor = _vm.editor) !== null && _vm$editor !== void 0 && _vm$editor.isActive('paragraph') ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor2;

        (_vm$editor2 = _vm.editor) === null || _vm$editor2 === void 0 ? void 0 : _vm$editor2.chain().focus().setParagraph().run();
      }
    }
  }, [_vm._v("Paragraph")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor3 = _vm.editor) !== null && _vm$editor3 !== void 0 && _vm$editor3.isActive('customTitleNode') ? 'selected' : ''],
    on: {
      "click": _vm.handleTitle
    }
  }, [_vm._v("Title")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor4 = _vm.editor) !== null && _vm$editor4 !== void 0 && _vm$editor4.isActive('heading', {
      level: 1
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor5;

        (_vm$editor5 = _vm.editor) === null || _vm$editor5 === void 0 ? void 0 : _vm$editor5.chain().focus().toggleHeading({
          level: 1
        }).run();
      }
    }
  }, [_vm._v("Heading 1")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor6 = _vm.editor) !== null && _vm$editor6 !== void 0 && _vm$editor6.isActive('heading', {
      level: 2
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor7;

        (_vm$editor7 = _vm.editor) === null || _vm$editor7 === void 0 ? void 0 : _vm$editor7.chain().focus().toggleHeading({
          level: 2
        }).run();
      }
    }
  }, [_vm._v("Heading 2")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor8 = _vm.editor) !== null && _vm$editor8 !== void 0 && _vm$editor8.isActive('heading', {
      level: 3
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor9;

        (_vm$editor9 = _vm.editor) === null || _vm$editor9 === void 0 ? void 0 : _vm$editor9.chain().focus().toggleHeading({
          level: 3
        }).run();
      }
    }
  }, [_vm._v("Heading 3")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor10 = _vm.editor) !== null && _vm$editor10 !== void 0 && _vm$editor10.isActive('heading', {
      level: 4
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor11;

        (_vm$editor11 = _vm.editor) === null || _vm$editor11 === void 0 ? void 0 : _vm$editor11.chain().focus().toggleHeading({
          level: 4
        }).run();
      }
    }
  }, [_vm._v("Heading 4")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor12 = _vm.editor) !== null && _vm$editor12 !== void 0 && _vm$editor12.isActive('heading', {
      level: 5
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor13;

        (_vm$editor13 = _vm.editor) === null || _vm$editor13 === void 0 ? void 0 : _vm$editor13.chain().focus().toggleHeading({
          level: 5
        }).run();
      }
    }
  }, [_vm._v("Heading 5")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor14 = _vm.editor) !== null && _vm$editor14 !== void 0 && _vm$editor14.isActive('heading', {
      level: 6
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor15;

        (_vm$editor15 = _vm.editor) === null || _vm$editor15 === void 0 ? void 0 : _vm$editor15.chain().focus().toggleHeading({
          level: 6
        }).run();
      }
    }
  }, [_vm._v("Heading 6")])])], 1) : _vm._e(), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    class: ['toolbar__btn', !((_vm$editor16 = _vm.editor) !== null && _vm$editor16 !== void 0 && _vm$editor16.can().undo()) || !_vm.hasChanges || !_vm.editable ? 'disabled' : ''],
    on: {
      "click": function click($event) {
        _vm.editable ? _vm.handleUndo() : false;
      }
    }
  }, [_c('ArrowUTurnLeftIcon')], 1)]), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    staticClass: "toolbar__btn",
    class: {
      'disabled': !_vm.editable,
      'selected': (_vm$editor17 = _vm.editor) === null || _vm$editor17 === void 0 ? void 0 : _vm$editor17.isActive('bold')
    },
    staticStyle: {
      "font-weight": "700"
    },
    on: {
      "click": function click($event) {
        var _vm$editor18;

        _vm.editable ? (_vm$editor18 = _vm.editor) === null || _vm$editor18 === void 0 ? void 0 : _vm$editor18.chain().focus().toggleBold().run() : false;
      }
    }
  }, [_c('BoldIcon')], 1), _c('div', {
    staticClass: "toolbar__btn",
    class: {
      'disabled': !_vm.editable,
      'selected': (_vm$editor19 = _vm.editor) === null || _vm$editor19 === void 0 ? void 0 : _vm$editor19.isActive('italic')
    },
    staticStyle: {
      "font-style": "italic"
    },
    on: {
      "click": function click($event) {
        var _vm$editor20;

        _vm.editable ? (_vm$editor20 = _vm.editor) === null || _vm$editor20 === void 0 ? void 0 : _vm$editor20.chain().focus().toggleItalic().run() : false;
      }
    }
  }, [_c('ItalicIcon')], 1), _c('div', {
    staticClass: "toolbar__btn",
    class: {
      'disabled': !_vm.editable,
      'selected': (_vm$editor21 = _vm.editor) === null || _vm$editor21 === void 0 ? void 0 : _vm$editor21.isActive('underline')
    },
    staticStyle: {
      "text-decoration": "underline"
    },
    on: {
      "click": function click($event) {
        var _vm$editor22;

        _vm.editable ? (_vm$editor22 = _vm.editor) === null || _vm$editor22 === void 0 ? void 0 : _vm$editor22.chain().focus().toggleUnderline().run() : false;
      }
    }
  }, [_c('UnderlineIcon')], 1), !_vm.isAutentiMode ? _c('div', {
    staticClass: "toolbar__btn",
    class: {
      'disabled': !_vm.editable,
      'selected': (_vm$editor23 = _vm.editor) === null || _vm$editor23 === void 0 ? void 0 : _vm$editor23.isActive('strike')
    },
    staticStyle: {
      "text-decoration": "line-through"
    },
    on: {
      "click": function click($event) {
        var _vm$editor24;

        _vm.editable ? (_vm$editor24 = _vm.editor) === null || _vm$editor24 === void 0 ? void 0 : _vm$editor24.chain().focus().toggleStrike().run() : false;
      }
    }
  }, [_c('StrikethroughIcon')], 1) : _vm._e()]), !_vm.isAutentiMode ? _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    class: ['toolbar__btn', !_vm.editable ? 'disabled' : '', (_vm$editor25 = _vm.editor) !== null && _vm$editor25 !== void 0 && _vm$editor25.isActive({
      textAlign: 'left'
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor26;

        _vm.editable ? (_vm$editor26 = _vm.editor) === null || _vm$editor26 === void 0 ? void 0 : _vm$editor26.chain().focus().setTextAlign('left').run() : false;
      }
    }
  }, [_c('Bars3CenterLeftIcon')], 1), _c('div', {
    class: ['toolbar__btn', !_vm.editable ? 'disabled' : '', (_vm$editor27 = _vm.editor) !== null && _vm$editor27 !== void 0 && _vm$editor27.isActive({
      textAlign: 'center'
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor28;

        _vm.editable ? (_vm$editor28 = _vm.editor) === null || _vm$editor28 === void 0 ? void 0 : _vm$editor28.chain().focus().setTextAlign('center').run() : false;
      }
    }
  }, [_c('Bars3Icon')], 1), _c('div', {
    class: ['toolbar__btn', !_vm.editable ? 'disabled' : '', (_vm$editor29 = _vm.editor) !== null && _vm$editor29 !== void 0 && _vm$editor29.isActive({
      textAlign: 'right'
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor30;

        _vm.editable ? (_vm$editor30 = _vm.editor) === null || _vm$editor30 === void 0 ? void 0 : _vm$editor30.chain().focus().setTextAlign('right').run() : false;
      }
    }
  }, [_c('Bars3BottomRightIcon')], 1), _c('div', {
    class: ['toolbar__btn', !_vm.editable ? 'disabled' : '', (_vm$editor31 = _vm.editor) !== null && _vm$editor31 !== void 0 && _vm$editor31.isActive({
      textAlign: 'justify'
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor32;

        _vm.editable ? (_vm$editor32 = _vm.editor) === null || _vm$editor32 === void 0 ? void 0 : _vm$editor32.chain().focus().setTextAlign('justify').run() : false;
      }
    }
  }, [_c('Bars3BottomLeftIcon')], 1)]) : _vm._e(), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    class: ['toolbar__btn', !_vm.editable ? 'disabled' : '', (_vm$editor33 = _vm.editor) !== null && _vm$editor33 !== void 0 && _vm$editor33.isActive('bulletList') ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor34;

        _vm.editable ? (_vm$editor34 = _vm.editor) === null || _vm$editor34 === void 0 ? void 0 : _vm$editor34.chain().focus().toggleBulletList().run() : false;
      }
    }
  }, [_c('ListBulletIcon')], 1), !_vm.isAutentiMode ? _c('div', {
    class: ['toolbar__btn', !_vm.editable ? 'disabled' : '', (_vm$editor35 = _vm.editor) !== null && _vm$editor35 !== void 0 && _vm$editor35.isActive('orderedList') ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor36;

        _vm.editable ? (_vm$editor36 = _vm.editor) === null || _vm$editor36 === void 0 ? void 0 : _vm$editor36.chain().focus().toggleOrderedList().run() : false;
      }
    }
  }, [_c('NumberedListIcon')], 1) : _vm._e(), !(_vm.isCleanMode || _vm.isAutentiMode) ? _c('div', {
    staticClass: "toolbar__btn",
    on: {
      "click": _vm.handleTable
    }
  }, [_c('TableCellsIcon')], 1) : _vm._e(), !(_vm.isCleanMode || _vm.isAutentiMode) ? _c('div', {
    staticClass: "toolbar__btn",
    on: {
      "click": _vm.handleSplitText
    }
  }, [_c('ViewColumnsIcon')], 1) : _vm._e(), !(_vm.isCleanMode || _vm.isAutentiMode) ? _c('div', {
    class: ['toolbar__btn', (_vm$editor37 = _vm.editor) !== null && _vm$editor37 !== void 0 && _vm$editor37.isActive('customTitleNode') ? 'selected' : ''],
    on: {
      "click": _vm.handleSection
    }
  }, [_vm._v("§")]) : _vm._e(), !(_vm.isCleanMode || _vm.isAutentiMode) ? _c('div', {
    class: ['toolbar__btn', (_vm$editor38 = _vm.editor) !== null && _vm$editor38 !== void 0 && _vm$editor38.isActive('customPageDivider') ? 'selected' : ''],
    on: {
      "click": _vm.handlePageDivider
    }
  }, [_c('MinusIcon')], 1) : _vm._e(), !_vm.isCleanMode && _vm.template ? _c('div', {
    staticClass: "toolbar__btn",
    on: {
      "click": _vm.openWatermarkModal
    }
  }, [_c('WatermarkIcon')], 1) : _vm._e()]), !_vm.isAutentiMode ? _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    class: ['toolbar__btn', !_vm.editable ? 'disabled' : '', !((_vm$editor39 = _vm.editor) !== null && _vm$editor39 !== void 0 && _vm$editor39.can().liftListItem('listItem')) ? 'disabled' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor40;

        _vm.editable ? (_vm$editor40 = _vm.editor) === null || _vm$editor40 === void 0 ? void 0 : _vm$editor40.chain().focus().liftListItem('listItem').run() : false;
      }
    }
  }, [_c('ChevronLeftIcon')], 1), _c('div', {
    class: ['toolbar__btn', !_vm.editable ? 'disabled' : '', !((_vm$editor41 = _vm.editor) !== null && _vm$editor41 !== void 0 && _vm$editor41.can().splitListItem('listItem')) ? 'disabled' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor42;

        _vm.editable ? (_vm$editor42 = _vm.editor) === null || _vm$editor42 === void 0 ? void 0 : _vm$editor42.chain().focus().sinkListItem('listItem').run() : false;
      }
    }
  }, [_c('ChevronRightIcon')], 1)]) : _vm._e(), _vm.isCleanMode && _vm.supportImages ? _c('file-uploader', {
    attrs: {
      "supported-file-formats": _vm.supportedFileFormats,
      "type": "simple",
      "icon": "UPLOAD",
      "btnText": "",
      "tooltipText": "Upload image",
      "infoText": "",
      "inverted": true,
      "multiple": false
    },
    on: {
      "input": function input($event) {
        return _vm.addFile($event);
      }
    }
  }) : _vm._e()], 1)]) : _c('div', {
    staticClass: "toolbar"
  }, [!_vm.isCleanMode ? _c('div', {
    class: ['toolbar-select', _vm.selectIsOpened ? 'opened' : ''],
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.selectIsOpened = !_vm.selectIsOpened;
      }
    }
  }, [_c('div', {
    staticClass: "toolbar-select__value"
  }, [_c('span', [_vm._v(_vm._s(_vm.currentTextType))]), _c('img', {
    staticClass: "toolbar-select__arrow",
    attrs: {
      "src": require("@/assets/icons/arrow.svg")
    }
  })]), _c('simplebar', {
    staticClass: "toolbar-select__dropdown",
    attrs: {
      "data-simplebar-auto-hide": "false"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.selectIsOpened = false;
      }
    }
  }, [_c('div', {
    class: ['toolbar-select__option', (_vm$editor43 = _vm.editor) !== null && _vm$editor43 !== void 0 && _vm$editor43.isActive('paragraph') ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor44;

        (_vm$editor44 = _vm.editor) === null || _vm$editor44 === void 0 ? void 0 : _vm$editor44.chain().focus().setParagraph().run();
      }
    }
  }, [_vm._v("Paragraph")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor45 = _vm.editor) !== null && _vm$editor45 !== void 0 && _vm$editor45.isActive('customTitleNode') ? 'selected' : ''],
    on: {
      "click": _vm.handleTitle
    }
  }, [_vm._v("Title")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor46 = _vm.editor) !== null && _vm$editor46 !== void 0 && _vm$editor46.isActive('heading', {
      level: 1
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor47;

        (_vm$editor47 = _vm.editor) === null || _vm$editor47 === void 0 ? void 0 : _vm$editor47.chain().focus().toggleHeading({
          level: 1
        }).run();
      }
    }
  }, [_vm._v("Heading 1")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor48 = _vm.editor) !== null && _vm$editor48 !== void 0 && _vm$editor48.isActive('heading', {
      level: 2
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor49;

        (_vm$editor49 = _vm.editor) === null || _vm$editor49 === void 0 ? void 0 : _vm$editor49.chain().focus().toggleHeading({
          level: 2
        }).run();
      }
    }
  }, [_vm._v("Heading 2")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor50 = _vm.editor) !== null && _vm$editor50 !== void 0 && _vm$editor50.isActive('heading', {
      level: 3
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor51;

        (_vm$editor51 = _vm.editor) === null || _vm$editor51 === void 0 ? void 0 : _vm$editor51.chain().focus().toggleHeading({
          level: 3
        }).run();
      }
    }
  }, [_vm._v("Heading 3")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor52 = _vm.editor) !== null && _vm$editor52 !== void 0 && _vm$editor52.isActive('heading', {
      level: 4
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor53;

        (_vm$editor53 = _vm.editor) === null || _vm$editor53 === void 0 ? void 0 : _vm$editor53.chain().focus().toggleHeading({
          level: 4
        }).run();
      }
    }
  }, [_vm._v("Heading 4")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor54 = _vm.editor) !== null && _vm$editor54 !== void 0 && _vm$editor54.isActive('heading', {
      level: 5
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor55;

        (_vm$editor55 = _vm.editor) === null || _vm$editor55 === void 0 ? void 0 : _vm$editor55.chain().focus().toggleHeading({
          level: 5
        }).run();
      }
    }
  }, [_vm._v("Heading 5")]), _c('div', {
    class: ['toolbar-select__option', (_vm$editor56 = _vm.editor) !== null && _vm$editor56 !== void 0 && _vm$editor56.isActive('heading', {
      level: 6
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor57;

        (_vm$editor57 = _vm.editor) === null || _vm$editor57 === void 0 ? void 0 : _vm$editor57.chain().focus().toggleHeading({
          level: 6
        }).run();
      }
    }
  }, [_vm._v("Heading 6")])])], 1) : _vm._e(), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    class: ['toolbar__btn', !((_vm$editor58 = _vm.editor) !== null && _vm$editor58 !== void 0 && _vm$editor58.can().undo()) || !_vm.hasChanges ? 'disabled' : ''],
    on: {
      "click": function click($event) {
        return _vm.handleUndo();
      }
    }
  }, [_c('ArrowUTurnLeftIcon')], 1)]), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Bold',
      expression: "'Bold'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "toolbar__btn",
    class: {
      'selected': (_vm$editor59 = _vm.editor) === null || _vm$editor59 === void 0 ? void 0 : _vm$editor59.isActive('bold')
    },
    staticStyle: {
      "font-weight": "700"
    },
    on: {
      "click": function click($event) {
        var _vm$editor60;

        (_vm$editor60 = _vm.editor) === null || _vm$editor60 === void 0 ? void 0 : _vm$editor60.chain().focus().toggleBold().run();
      }
    }
  }, [_c('BoldIcon')], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Italic',
      expression: "'Italic'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "toolbar__btn",
    class: {
      'selected': (_vm$editor61 = _vm.editor) === null || _vm$editor61 === void 0 ? void 0 : _vm$editor61.isActive('italic')
    },
    staticStyle: {
      "font-style": "italic"
    },
    on: {
      "click": function click($event) {
        var _vm$editor62;

        (_vm$editor62 = _vm.editor) === null || _vm$editor62 === void 0 ? void 0 : _vm$editor62.chain().focus().toggleItalic().run();
      }
    }
  }, [_c('ItalicIcon')], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Underline',
      expression: "'Underline'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "toolbar__btn",
    class: {
      'selected': (_vm$editor63 = _vm.editor) === null || _vm$editor63 === void 0 ? void 0 : _vm$editor63.isActive('underline')
    },
    staticStyle: {
      "text-decoration": "underline"
    },
    on: {
      "click": function click($event) {
        var _vm$editor64;

        (_vm$editor64 = _vm.editor) === null || _vm$editor64 === void 0 ? void 0 : _vm$editor64.chain().focus().toggleUnderline().run();
      }
    }
  }, [_c('UnderlineIcon')], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Strike',
      expression: "'Strike'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "toolbar__btn",
    class: {
      'selected': (_vm$editor65 = _vm.editor) === null || _vm$editor65 === void 0 ? void 0 : _vm$editor65.isActive('strike')
    },
    staticStyle: {
      "text-decoration": "line-through"
    },
    on: {
      "click": function click($event) {
        var _vm$editor66;

        (_vm$editor66 = _vm.editor) === null || _vm$editor66 === void 0 ? void 0 : _vm$editor66.chain().focus().toggleStrike().run();
      }
    }
  }, [_c('StrikethroughIcon')], 1)]), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Align left',
      expression: "'Align left'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', (_vm$editor67 = _vm.editor) !== null && _vm$editor67 !== void 0 && _vm$editor67.isActive({
      textAlign: 'left'
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor68;

        (_vm$editor68 = _vm.editor) === null || _vm$editor68 === void 0 ? void 0 : _vm$editor68.chain().focus().setTextAlign('left').run();
      }
    }
  }, [_c('Bars3CenterLeftIcon')], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Align center',
      expression: "'Align center'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', (_vm$editor69 = _vm.editor) !== null && _vm$editor69 !== void 0 && _vm$editor69.isActive({
      textAlign: 'center'
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor70;

        (_vm$editor70 = _vm.editor) === null || _vm$editor70 === void 0 ? void 0 : _vm$editor70.chain().focus().setTextAlign('center').run();
      }
    }
  }, [_c('Bars3Icon')], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Align right',
      expression: "'Align right'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', (_vm$editor71 = _vm.editor) !== null && _vm$editor71 !== void 0 && _vm$editor71.isActive({
      textAlign: 'right'
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor72;

        (_vm$editor72 = _vm.editor) === null || _vm$editor72 === void 0 ? void 0 : _vm$editor72.chain().focus().setTextAlign('right').run();
      }
    }
  }, [_c('Bars3BottomRightIcon')], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Justify',
      expression: "'Justify'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', (_vm$editor73 = _vm.editor) !== null && _vm$editor73 !== void 0 && _vm$editor73.isActive({
      textAlign: 'justify'
    }) ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor74;

        (_vm$editor74 = _vm.editor) === null || _vm$editor74 === void 0 ? void 0 : _vm$editor74.chain().focus().setTextAlign('justify').run();
      }
    }
  }, [_c('Bars3BottomLeftIcon')], 1)]), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Unordered list',
      expression: "'Unordered list'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', (_vm$editor75 = _vm.editor) !== null && _vm$editor75 !== void 0 && _vm$editor75.isActive('bulletList') ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor76;

        (_vm$editor76 = _vm.editor) === null || _vm$editor76 === void 0 ? void 0 : _vm$editor76.chain().focus().toggleBulletList().run();
      }
    }
  }, [_c('ListBulletIcon')], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Ordered list',
      expression: "'Ordered list'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', (_vm$editor77 = _vm.editor) !== null && _vm$editor77 !== void 0 && _vm$editor77.isActive('orderedList') ? 'selected' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor78;

        (_vm$editor78 = _vm.editor) === null || _vm$editor78 === void 0 ? void 0 : _vm$editor78.chain().focus().toggleOrderedList().run();
      }
    }
  }, [_c('NumberedListIcon')], 1), !_vm.isCleanMode ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Table',
      expression: "'Table'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "toolbar__btn",
    on: {
      "click": _vm.handleTable
    }
  }, [_c('TableCellsIcon')], 1) : _vm._e(), !_vm.isCleanMode ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Split text',
      expression: "'Split text'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "toolbar__btn",
    on: {
      "click": _vm.handleSplitText
    }
  }, [_c('ViewColumnsIcon')], 1) : _vm._e(), !_vm.isCleanMode ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Add paragraph',
      expression: "'Add paragraph'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', (_vm$editor79 = _vm.editor) !== null && _vm$editor79 !== void 0 && _vm$editor79.isActive('customSectionNode') ? 'selected' : ''],
    on: {
      "click": _vm.handleSection
    }
  }, [_vm._v("§")]) : _vm._e(), !_vm.isCleanMode ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Add page break',
      expression: "'Add page break'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', (_vm$editor80 = _vm.editor) !== null && _vm$editor80 !== void 0 && _vm$editor80.isActive('customPageDivider') ? 'selected' : ''],
    on: {
      "click": _vm.handlePageDivider
    }
  }, [_c('MinusIcon')], 1) : _vm._e(), !_vm.isCleanMode && _vm.template ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Watermark options',
      expression: "'Watermark options'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "toolbar__btn",
    on: {
      "click": _vm.openWatermarkModal
    }
  }, [_c('WatermarkIcon')], 1) : _vm._e()]), _c('div', {
    staticClass: "toolbar__group"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Indent left',
      expression: "'Indent left'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', !((_vm$editor81 = _vm.editor) !== null && _vm$editor81 !== void 0 && _vm$editor81.can().liftListItem('listItem')) ? 'disabled' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor82;

        (_vm$editor82 = _vm.editor) === null || _vm$editor82 === void 0 ? void 0 : _vm$editor82.chain().focus().liftListItem('listItem').run();
      }
    }
  }, [_c('ChevronLeftIcon')], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Indent right',
      expression: "'Indent right'",
      modifiers: {
        "hover": true
      }
    }],
    class: ['toolbar__btn', !((_vm$editor83 = _vm.editor) !== null && _vm$editor83 !== void 0 && _vm$editor83.can().splitListItem('listItem')) ? 'disabled' : ''],
    on: {
      "click": function click($event) {
        var _vm$editor84;

        (_vm$editor84 = _vm.editor) === null || _vm$editor84 === void 0 ? void 0 : _vm$editor84.chain().focus().sinkListItem('listItem').run();
      }
    }
  }, [_c('ChevronRightIcon')], 1)])])], 1) : _vm._e(), _c('div', {
    class: ['wysiwyg-editor__content', _vm.maxLength > 0 && _vm.charactersAmount > _vm.maxLength ? 'error' : '']
  }, [_c('div', {
    staticClass: "wysiwyg-editor__watermark"
  }, [(_vm$template = _vm.template) !== null && _vm$template !== void 0 && _vm$template.watermarkPath ? _c('img', {
    attrs: {
      "src": (_vm$template2 = _vm.template) === null || _vm$template2 === void 0 ? void 0 : _vm$template2.watermarkPath,
      "alt": "Watermark"
    }
  }) : _vm._e()]), _c('editor-content', {
    staticClass: "tiptap",
    attrs: {
      "editor": _vm.editor
    }
  })], 1), _vm.maxLength > 0 ? _c('div', {
    class: ['wysiwyg-editor__counter', _vm.charactersAmount > _vm.maxLength ? 'red' : '']
  }, [_c('p', {
    staticClass: "text-right mt-3"
  }, [_vm._v(_vm._s(_vm.charactersAmount) + "/" + _vm._s(_vm.maxLength) + " characters")])]) : _vm._e(), _c('div', {
    staticClass: "wysiwyg-editor__footer"
  }, [_vm._t("footer")], 2)]), !_vm.simple && !(_vm.isCleanMode || _vm.isAutentiMode) ? _c('div', {
    staticClass: "wysiwyg-editor__sidebar"
  }, [_vm._t("sidebar")], 2) : _vm._e()], 2), (_vm$tableModal = _vm.tableModal) !== null && _vm$tableModal !== void 0 && _vm$tableModal.show ? _c('GenproxModal', {
    attrs: {
      "header": "Add table",
      "cancelText": "Cancel",
      "confirmText": "Confirm",
      "smallerActionsPadding": true,
      "disabled": !(((_vm$tableModal2 = _vm.tableModal) === null || _vm$tableModal2 === void 0 ? void 0 : _vm$tableModal2.rows) >= 1 && ((_vm$tableModal3 = _vm.tableModal) === null || _vm$tableModal3 === void 0 ? void 0 : _vm$tableModal3.cols) >= 1)
    },
    on: {
      "close": _vm.closeTableModal,
      "cancel": _vm.closeTableModal,
      "confirm": _vm.confirmTableModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$tableModal4, _vm$tableModal5;

        return [_c('div', {
          staticClass: "gn-modal__form"
        }, [_c('sygni-input', {
          attrs: {
            "label": "Rows",
            "value": (_vm$tableModal4 = _vm.tableModal) === null || _vm$tableModal4 === void 0 ? void 0 : _vm$tableModal4.rows,
            "legacyStyling": false,
            "displayFormat": 'integer'
          },
          on: {
            "blur": _vm.setTableRows
          }
        }), _c('sygni-input', {
          attrs: {
            "label": "Columns",
            "value": (_vm$tableModal5 = _vm.tableModal) === null || _vm$tableModal5 === void 0 ? void 0 : _vm$tableModal5.cols,
            "legacyStyling": false,
            "displayFormat": 'integer'
          },
          on: {
            "blur": _vm.setTableCols
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 1016925122)
  }) : _vm._e(), _vm.template && (_vm$watermarkModal = _vm.watermarkModal) !== null && _vm$watermarkModal !== void 0 && _vm$watermarkModal.show ? _c('GenproxModal', {
    staticClass: "watermark-modal",
    attrs: {
      "header": "Watermark options",
      "description": "Upload your custom image to display it in the background."
    },
    on: {
      "cancel": _vm.closeWatermarkModal,
      "close": _vm.closeWatermarkModal,
      "confirm": _vm.confirmWatermarkChanges
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$watermarkModal2;

        return [_c('sygni-droparea', {
          attrs: {
            "supportedFileFormats": ['jpg', 'png', 'jpeg'],
            "preview": true,
            "previewUrl": (_vm$watermarkModal2 = _vm.watermarkModal) === null || _vm$watermarkModal2 === void 0 ? void 0 : _vm$watermarkModal2.tempImageUrl,
            "disabled": !_vm.editable
          },
          on: {
            "input": _vm.uploadWatermark,
            "previewRemoved": _vm.removeImagePreview
          }
        }, [_c('GenproxButton', {
          attrs: {
            "outline": true,
            "icon": _vm.CloudArrowDownIcon
          }
        }, [_vm._v("Upload watermark image")]), _c('p', {
          staticClass: "label"
        }, [_vm._v("Optimal size: 1240x1754px. Format: jpg, png, jpeg")])], 1)];
      },
      proxy: true
    }], null, false, 3528523227)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }